.cube-container {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  transform-style: preserve-3d;
}

.cube {
  transform-style: preserve-3d;
  position: relative;

  .face {
    background-color: red;
    position: absolute;

    &.left {
      right: 100%;
      transform-origin: right center;
      transform: rotateY(-90deg);
    }

    &.right {
      left: 100%;
      transform-origin: left center;
      transform: rotateY(90deg);
    }

    &.bottom {
      top: 100%;
      transform-origin: center top;
      transform: rotateX(-90deg);
    }

    &.top {
      bottom: 100%;
      transform-origin: center bottom;
      transform: rotateX(90deg);
    }
  }
}
