.game {
  .key-button {
    appearance: none;
    display: grid;
    align-items: center;
    gap: 15px;
    padding: 10px 15px;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    font-size: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    position: fixed;
    grid-template-columns: repeat(2, min-content);
    letter-spacing: 0.2px;
    text-transform: uppercase;
    font-weight: extra-bold;
    z-index: 4;

    .key {
      border: 1px solid rgba(255, 255, 255, 0.8);
      padding: 8px;
      font-size: 10px;
      opacity: 0.7;
      letter-spacing: 1px;
      border-radius: 0.2rem;
      font-style: italic;
      font-weight: normal;
    }

    &:hover .key {
      opacity: 1;
    }

    &:active {
      margin-top: 2px;
    }

    &.game-settings-button {
      top: 15px;
      left: 15px;
    }

    &.restart-button {
      top: 15px;
      right: 15px;
    }
  }
}
