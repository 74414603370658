.game {
  .covid-19 {
    --bg-color: #ec80a9;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease;
    width: 40px;
    height: 40px;

    &.hit {
      --bg-color: #77ce85;
      opacity: 0;

      .body {
        box-shadow: inset 0px 10px 10px 0px #61b959,
          inset 0px -10px 10px 0px #227545;
      }

      .tentacle {
        --tentacle-offset: 6px;
        --tentacle-shadow-color: #5db465;
        --tentacle-color: #5bc55b;
        max-height: 8px;
      }
    }

    .eyes {
      position: absolute;
      top: 17px;
      left: 7px;
      height: 6px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .eye {
        background-color: #4b2931;
        width: 7px;
        height: 7px;
        border-radius: 50%;
      }
    }

    .body {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: var(--bg-color);
      box-shadow: inset 0 -10px 0 0 #dd699f;
    }

    .tentacle {
      --tentacle-offset: 0px;
      --tentacle-color: var(--bg-color);

      position: absolute;
      height: 100%;
      max-height: 12px;
      width: 8px;
      border-radius: 6px;
      background-color: var(--tentacle-color);

      &--1,
      &--2,
      &--3 {
        --tentacle-shadow-color: #da76a8;
      }

      &--1 {
        top: calc(-6px - var(--tentacle-offset));
        left: calc(50% - 3px);
        box-shadow: inset 0 -6px 0 0 var(--tentacle-shadow-color);
      }

      &--2 {
        top: calc(-1px - var(--tentacle-offset));
        left: 3px;
        transform: rotate(-40deg);
        box-shadow: inset 1px -1px 0 0 var(--tentacle-shadow-color);
      }

      &--3 {
        top: calc(-1px - var(--tentacle-offset));
        transform: rotate(40deg);
        right: 3px;
        box-shadow: inset -1px -1px 0 0 var(--tentacle-shadow-color);
      }

      &--4,
      &--5 {
        --tentacle-shadow-color: #dd699f;
      }

      &--4 {
        top: 14px;
        transform: rotate(90deg);
        right: calc(-4px - var(--tentacle-offset) / 1.3);
        box-shadow: inset -1px 0 0 1px var(--tentacle-shadow-color);
      }

      &--5 {
        top: 14px;
        transform: rotate(90deg);
        left: calc(-4px - var(--tentacle-offset) / 1.3);
        box-shadow: inset -1px 0 0 1px var(--tentacle-shadow-color);
      }

      &--6,
      &--7,
      &--8 {
        --tentacle-shadow-color: #b45a8c;
      }

      &--6 {
        bottom: calc(-6px - var(--tentacle-offset));
        left: calc(50% - 3px);
        box-shadow: inset 0 -6px 0px 1px var(--tentacle-shadow-color);
      }

      &--7 {
        --tentacle-color: #d865a8;
        bottom: calc(-1px - var(--tentacle-offset));
        left: 3px;
        transform: rotate(40deg);
        box-shadow: inset -1px -2px 0 1px var(--tentacle-shadow-color);
      }

      &--8 {
        --tentacle-color: #d865a8;
        bottom: calc(-1px - var(--tentacle-offset));
        transform: rotate(-40deg);
        right: 3px;
        box-shadow: inset 1px -2px 0 1px var(--tentacle-shadow-color);
      }
    }
  }
}
