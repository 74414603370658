.game {
  .bullseye {
    --bg-color: #d44b59;
    border-radius: 50%;
    position: absolute;
    background-color: var(--bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    width: 40px;
    height: 40px;

    &.hit {
      --bg-color: #2dc0fa;
      opacity: 0;
    }

    .white,
    .red {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      border-radius: 50%;
    }

    .white {
      background-color: white;
    }

    .red {
      background-color: var(--bg-color);
    }
  }
}
