.game {
  .points-board {
    --border: 1px solid rgba(255, 255, 255, 0.12);
    padding: 15px 5px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0.25rem;
    align-items: center;
    color: rgb(240, 239, 239);
    display: grid;
    grid-template-columns: minmax(130px, max-content) repeat(2, max-content);
    margin-top: 15px;

    &.with-reaction-time {
      grid-template-columns: minmax(130px, max-content) repeat(3, max-content);
    }

    .counter-group {
      display: flex;
      flex-direction: column;
      padding: 0 18px;
      text-align: center;
      border-right: var(--border);

      &:last-of-type {
        border-right: none;
      }

      .counter {
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 5px;

        &.good {
          color: #73da4a;
        }
        &.bad {
          color: #e65d4b;
        }

        .help-counter {
          font-weight: normal;
          font-size: 11px;
          width: 44px;
          display: inline-block;
          text-align: center;
        }
      }

      .counter-label {
        font-size: 11px;
      }
    }
  }
}
