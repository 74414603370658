.game {
  --main-bg-color: #3e495e;
  width: 100%;
  height: 100%;
  background: var(--main-bg-color);
  background: radial-gradient(circle, var(--main-bg-color) 0%, #1a1c25 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: relative;

  &:not(.is-mobile) {
    min-width: 550px;
    min-height: 750px;
  }

  * {
    &:not(.fas):not(.far) {
      font-family: "Inter", "Roboto", "sans-serif";
    }
  }

  .error {
    color: white;
    margin: auto 0;
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    line-height: 22px;
  }

  .flashlight {
    top: 0;
    left: 0;
    background-color: white;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.007;
  }

  .playable-area {
    width: 100%;
    flex: 1;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: url("./assets/crosshair.cur"), crosshair;
    position: relative;
    overflow: hidden;
    transition: filter 0.3s ease;
    margin-bottom: 40px;

    &:active {
      cursor: url("./assets/crosshair_down.cur"), crosshair;
    }

    * {
      user-select: none;
    }

    &.blur {
      transition: filter 0.5s ease;
      filter: blur(15px);
    }
  }

  .overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .mini-logo {
    height: 13px;
    position: fixed;
    z-index: 4;
    bottom: 15px;
    left: 15px;
    opacity: 0.2;
  }

  .default-logo {
    margin: 0 auto;
    margin-bottom: 30px;
    height: 25px;
    flex-shrink: 0;
  }
}
