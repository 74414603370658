.game {
  .target-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    &.bring-front {
      z-index: 3;
    }
  }
}
