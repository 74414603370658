.game {
  .game-settings {
    --text-size: 16px;
    --border-thickness: 1px;
    --border-color: rgba(181, 201, 255, 0.2);
    transition: opacity 0.3s ease;
    opacity: 0;

    &:not(.visible) {
      visibility: hidden;
    }

    &.visible {
      transition: opacity 1s ease;
      opacity: 1;
    }

    .game-settings-container {
      margin: auto 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .game-setting-group {
        margin-bottom: 10px;
        position: relative;

        &:last-of-type {
          margin-bottom: 20px;
        }

        .game-setting:first-of-type {
          border-radius: 0.25rem 0.25rem 0 0;
          padding-top: 26px;
        }

        .game-setting:last-of-type {
          border-radius: 0 0 0.25rem 0.25rem;
          padding-bottom: 26px;
        }

        .game-setting {
          height: 34px;
          display: grid;
          align-items: center;
          grid-template-columns: 320px 105px;
          gap: 20px;
          margin-bottom: 1px;
          background-color: rgba(125, 146, 202, 0.122);
          padding: 12px 35px;

          label {
            color: white;
            font-size: var(--text-size);
            padding-left: 5px;

            .help-text {
              margin-top: 4px;
              font-size: 11px;
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

  input,
  select {
    outline: none;
    appearance: none;
    border: none;
  }

  .input {
    padding: 3px 5px;
    text-align: center;
    font-size: var(--text-size);
    font-weight: bold;
    color: white;
    background-color: rgba(0, 0, 0, 0);
    border-bottom: var(--border-thickness) solid var(--border-color);
    -moz-appearance: textfield;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .checkbox {
    --size: calc(var(--text-size) + 8px);
    position: relative;
    cursor: pointer;
    width: var(--size);
    height: var(--size);
    margin: 0 auto;

    &::before {
      box-sizing: border-box;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      border: var(--border-thickness) solid var(--border-color);
      position: absolute;
      content: "";
      background-color: rgba(0, 0, 0, 0);
      transition: all 0.2s ease;
      font-size: calc(var(--size) - 10px);
    }

    &:checked:before {
      border: var(--border-thickness) solid #4677f5;
      background-color: #4677f5;
      color: white;
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: grid;
      place-items: center;
      transition: all 0.2s ease;
    }
  }

  .select-wrapper {
    position: relative;
    height: 28px;
    background-color: rgba(125, 146, 202, 0.112);
    border-radius: 0.25rem;
    color: white;

    &:hover {
      background-color: rgba(125, 146, 202, 0.2);
    }

    .select {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 5px 8px;
      box-sizing: border-box;
      font-size: 14px;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0);
      color: white;

      option {
        color: black;
      }
    }

    .chevron {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      display: grid;
      place-items: center;
      padding: 0 8px;
      font-size: 13px;
      box-sizing: border-box;
      z-index: 1;
      padding-top: 2px;
    }
  }
}
