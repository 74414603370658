.game {
  .game-over {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 0;

    .points-board {
      margin-top: 0 !important;
      margin-bottom: 20px;
    }
  }
}
