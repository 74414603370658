.game {
  .gun {
    position: relative;
    transform-style: preserve-3d;
    transform-origin: center center 10px;

    .blast {
      --width: 5px;
      --height: 5px;

      width: var(--width);
      height: var(--height);
      position: absolute;
      top: calc(50% - var(--height) / 2);
      left: calc(50% - var(--width) / 2);
      transform: translateZ(-150px);
      background-color: #ffffff;
      border-radius: 50%;
      box-shadow: 0 0 10px 10px #ffffff, 0 0 30px 15px #20c0ff,
        0 0 100px 40px #4677f5;
    }

    .part {
      transform-style: preserve-3d;
      position: absolute;

      &.blue-detail {
        bottom: -3px;
        transform: translateZ(-15px);
        left: -3px;
      }

      &.handle {
        top: calc(100% - 1px);
        left: 50%;
        transform: translateX(-50%) translateZ(-10px);
      }

      &.trigger {
        top: 100%;
        left: 50%;
        transform: translateX(-50%) translateZ(-40px);
      }

      &.aim {
        bottom: calc(100%);
        left: 50%;
        transform: translateX(-50%) translateZ(-5px);
      }

      &.main--detail {
        top: -4px;
        left: 50%;
        transform: translateX(-50%) translateZ(5px);
      }
    }
  }
}
